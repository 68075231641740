import React from "react";
import { css } from "@emotion/react";

import CopyButton from "./CopyButton";

export default function CopyableCodeSnippet({
  isTerminalSnippet = false,
  code,
}) {
  const terminalPrompt = "> ";
  return (
    <pre>
      <code style={{ display: "flex" }}>
        <div
          css={css`
            flex: 1;
          `}
        >
          {isTerminalSnippet ? terminalPrompt : ""}
          {code}
        </div>
        <CopyButton
          text={code}
          css={css`
            align-self: center;
          `}
        />
      </code>
    </pre>
  );
}

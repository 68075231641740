import React from "react";
import { css } from "@emotion/react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import ClipboardIcon from "../assets/svg/clipboard.svg";

export default function CopyButton({ text, ...props }) {
  return (
    <CopyToClipboard text={text} css={css`
      cursor: pointer;
      max-height: 1rem;
    `} {...props}>
      <ClipboardIcon css={css`
        opacity: 0.8;
      `} />
    </CopyToClipboard>
  );
}

import React from 'react';
import { css } from "@emotion/react";

export default function ImageCaption({ caption }) {
  return <p css={css`
    text-align: center;
    font-style: italic;
    margin-top: -1rem;
  `}>{caption}</p>; 
} 

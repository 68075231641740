import React from 'react';

import CenteredExcerpt from './CenteredExcerpt';

export default function Forward({ text }) {
  return (
    <CenteredExcerpt>
      <em>
        {text}
      </em>
    </CenteredExcerpt>
  );
}

import React from 'react';
import { css } from "@emotion/react";

export default function CenteredExcerpt({ children }) {
  return (
    <p css={css`
      padding: 0.5rem;
      text-align: center;
    `}>
      {children}
    </p>
  );
}
